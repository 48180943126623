import FileInput from 'components/file-input'
import Loader from 'components/loader'
import { useState } from 'react'
import { toBase64 } from 'utils'
import Editor from './components/editor'

const ImageEditor = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [currentImage, setCurrentImage] = useState<string>('')
  const [imageType, setImageType] = useState<string>('')

  const handleFileUpload = async (uploadedFile: File) => {
    if (uploadedFile) {
      setIsLoading(true)
      const uploadedFileBased64 = await toBase64(uploadedFile)
      setImageType(uploadedFile.type)
      const img: HTMLImageElement = document.createElement('img')
      img.src = uploadedFileBased64
      setCurrentImage(uploadedFileBased64)
      setIsLoading(false)
    }
  }

  const renderImageEditor = () => <Editor imageType={imageType} imgUrl={currentImage} onReset={setCurrentImage} />

  return (
    <div className="flex-vertical justify-center min-h-screen-main">
      {!currentImage && (
        <div className="w-full text-center">
          <h1 className="page-title">
            <span className="block xl:inline">Image Editor</span>
          </h1>
          <FileInput handleFileUpload={handleFileUpload} />
        </div>
      )}
      {isLoading ? <Loader /> : currentImage ? renderImageEditor() : null}
    </div>
  )
}

export default ImageEditor
