import { MoonIcon, SunIcon } from '@heroicons/react/outline'
import useDarkMode from 'hooks/useDarkMode'
import { memo, useState } from 'react'

const ThemeToggle = () => {
  const { updateDarkMode } = useDarkMode()
  const isLightMode = localStorage.theme === 'light'
  const [isLight, setIsLight] = useState(isLightMode)

  const onChange = () => {
    setIsLight(!isLight)
    updateDarkMode(!isLight)
  }

  return (
    <div className="flex justify-between w-32">
      <SunIcon className="h-6 w-6 mr-3" aria-hidden="true" />
      <input
        checked={!isLight}
        data-act-class="ACTIVECLASS"
        data-toggle-theme="light,dark"
        className="toggle"
        type="checkbox"
        onChange={onChange}
      />
      <MoonIcon className="h-6 w-6 ml-3" aria-hidden="true" />
    </div>
  )
}

export default memo(ThemeToggle)
