import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowsExpandIcon,
  BackspaceIcon,
  DownloadIcon,
  RefreshIcon,
  ScissorsIcon,
  SwitchHorizontalIcon,
  SwitchVerticalIcon,
  TrashIcon,
  XIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from '@heroicons/react/outline'
import Modal from 'components/download-modal'

interface Props {
  onAction: (action: string) => void
  fileType: string
  onDownload: (fileName: string) => void
}

const Toolbar = ({ onAction, fileType, onDownload }: Props) => {
  return (
    <div className="flex flex-col-reverse md:flex-row justify-center mt-3 md:mt-5 md:mb-5">
      <div className="flex justify-center bg-opacity-50 bg-neutral rounded-md py-3 mt-1 md:mt-0 mx-3 md:mx-0">
        <div className="tooltip" data-tip="Move Mode" onClick={() => onAction('move')}>
          <ArrowsExpandIcon className="h-6 w-6 ml-3 hover:cursor-pointer" aria-hidden="true" />
        </div>
        <div className="tooltip" data-tip="Crop Mode" onClick={() => onAction('crop')}>
          <XIcon className="h-6 w-6 ml-3 hover:cursor-pointer" aria-hidden="true" />
        </div>
        <div className="tooltip" data-tip="Zoom In" onClick={() => onAction('zoom-in')}>
          <ZoomInIcon className="h-6 w-6 ml-3 hover:cursor-pointer" aria-hidden="true" />
        </div>
        <div className="tooltip" data-tip="Zoom Out" onClick={() => onAction('zoom-out')}>
          <ZoomOutIcon className="h-6 w-6 ml-3 hover:cursor-pointer" aria-hidden="true" />
        </div>
        <div className="tooltip" data-tip="Rotate Left" onClick={() => onAction('rotate-left')}>
          <ArrowLeftIcon className="h-6 w-6 ml-3 hover:cursor-pointer" aria-hidden="true" />
        </div>
        <div className="tooltip" data-tip="Rotate Right" onClick={() => onAction('rotate-right')}>
          <ArrowRightIcon className="h-6 w-6 ml-3 hover:cursor-pointer" aria-hidden="true" />
        </div>
        <div className="tooltip" data-tip="Flip Horizontal" onClick={() => onAction('flip-horizontal')}>
          <SwitchHorizontalIcon className="h-6 w-6 ml-3 hover:cursor-pointer" aria-hidden="true" />
        </div>
        <div className="tooltip" data-tip="Flip Vertical" onClick={() => onAction('flip-vertical')}>
          <SwitchVerticalIcon className="h-6 w-6 ml-3 mr-3 hover:cursor-pointer" aria-hidden="true" />
        </div>
      </div>
      <div className="hidden md:flex divider divider-horizontal" />
      <div className="flex justify-center bg-opacity-50 bg-neutral rounded-md py-3  mx-20 md:mx-0">
        <div className="tooltip" data-tip="Reset" onClick={() => onAction('reset')}>
          <BackspaceIcon className="h-6 w-6 ml-3 hover:cursor-pointer" aria-hidden="true" />
        </div>
        <div className="tooltip" data-tip="Restore" onClick={() => onAction('restore')}>
          <RefreshIcon className="h-6 w-6 ml-3 hover:cursor-pointer" aria-hidden="true" />
        </div>
        <div className="tooltip" data-tip="Clear Crop Box" onClick={() => onAction('clear')}>
          <TrashIcon className="h-6 w-6 ml-3 hover:cursor-pointer" aria-hidden="true" />
        </div>
        <div className="tooltip" data-tip="Crop" onClick={() => onAction('crop-image')}>
          <ScissorsIcon className="h-6 w-6 ml-3 hover:cursor-pointer" aria-hidden="true" />
        </div>
        <div className="tooltip" data-tip="Download">
          <Modal onDownload={onDownload} fileType={fileType}>
            <label htmlFor="download-modal">
              <DownloadIcon className="h-6 w-6 ml-3 mr-3 hover:cursor-pointer" aria-hidden="true" />
            </label>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default Toolbar
