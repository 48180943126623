import useDarkMode from 'hooks/useDarkMode'
import { useEffect } from 'react'
import Router from 'router'
import { themeChange } from 'theme-change'

const App = () => {
  const { loadDarkMode } = useDarkMode()
  useEffect(() => {
    // support daisyUI theme mode
    themeChange(false)
    // support tailswind dark mode
    loadDarkMode()
  }, [])

  return (
    <div>
      <Router />
    </div>
  )
}

export default App
