import Modal from 'components/download-modal'

interface Props {
  children?: React.ReactNode
  disabled: boolean
  fileType: string
  onImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void
  onDownload: (filename: string) => void
  sidebarComponent: React.ReactNode
}

const Drawer = ({ children, disabled, sidebarComponent, fileType, onDownload, onImageUpload }: Props) => {
  return (
    <div className="drawer drawer-end h-full">
      <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content dark:scrollbar mt-10">
        <div className="flex justify-end mb-3 mr-3 md:mr-8">
          <label htmlFor="my-drawer-4" className={`drawer-button btn btn-primary ${disabled && 'btn-disabled'}`}>
            Edit
          </label>
          <Modal disabled={disabled} onDownload={onDownload} fileType={fileType} />
          <label htmlFor="file-uploader" className={`drawer-button btn btn-primary ml-3 ${disabled && 'btn-disabled'}`}>
            Reupload
          </label>
          <input
            className="hidden"
            id="file-uploader"
            placeholder="Select a file"
            type="file"
            accept="image/png, image/jpeg"
            onChange={onImageUpload}
          />
        </div>
        {/* { <!-- Page content here -->} */}
        <div>{children}</div>
      </div>
      <div className="drawer-side overflow-x-hidden min-h-screen-drawer">
        <label htmlFor="my-drawer-4" className="drawer-overlay"></label>
        <div className="menu p-4 w-fit bg-base-100 text-base-content">
          {/* {<!-- Sidebar content here -->} */}
          {sidebarComponent}
        </div>
      </div>
    </div>
  )
}

export default Drawer
