import Drawer from 'components/drawer'
import { saveAs } from 'file-saver'
import ReactCompareImage from 'libraries/react-compare-image'
import { useState } from 'react'
import Resizer from 'react-image-file-resizer'
import { getFileType, toBase64 } from 'utils'
import FileInput from '../file-input'
import Loader from '../loader'
import Comparison from './components/comparsion'
import ImageStats from './components/image-stats'
import Settings from './components/settings'

export type ResizerTypeSettings = keyof ResizerType
export type ResizerType = typeof DEFAULT_SETTINGS
export const DEFAULT_SETTINGS = {
  width: 500,
  height: 500,
  quality: 70,
  rotation: 0,
  minWidth: 100,
  minHeight: 100,
  fileType: 'jpeg',
  outputType: 'file',
}

const ImageResizer = () => {
  const [originalImage, setOriginalImage] = useState<File>()
  const [originalImageDimension, setOriginalImageDimension] = useState<{ [key: string]: number }>()
  const [currentImage, setCurrentImage] = useState<string>()
  const [updatedImage, setUpdatedImage] = useState<string>()
  const [updatedImageSize, setUpdatedImageSize] = useState<number>(0)
  const [settings, setSettings] = useState<ResizerType>(DEFAULT_SETTINGS)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const imageCallback = async (updatedImage: File) => {
    const updatedImageBased64 = await toBase64(updatedImage)
    setUpdatedImageSize(updatedImage.size)
    setUpdatedImage(updatedImageBased64)
    setIsLoading(false)
  }

  const resizeImage = (imageFile: File, imageSettings: ResizerType) => {
    const { width, height, fileType, quality, rotation, outputType, minWidth, minHeight } = imageSettings
    try {
      Resizer.imageFileResizer(
        imageFile,
        width,
        height,
        fileType,
        quality,
        rotation,
        (updatedImage) => imageCallback(updatedImage as File),
        outputType,
        minWidth,
        minHeight
      )
    } catch (err) {
      console.log(err)
    }
  }

  const fileChangedHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    const uploadedFile = event.target.files[0]
    await handleFileUpload(uploadedFile)
  }

  const handleFileUpload = async (uploadedFile: File) => {
    if (uploadedFile) {
      setIsLoading(true)
      const uploadedFileBased64 = await toBase64(uploadedFile)
      const img: HTMLImageElement = document.createElement('img')
      img.onload = function () {
        const fileType = getFileType(uploadedFile.type)
        const updatedSettings = { ...settings, ...{ width: img.width, height: img.height, fileType } }
        setOriginalImageDimension({ width: img.width, height: img.height })
        setSettings(updatedSettings)
        resizeImage(uploadedFile, updatedSettings)
      }
      img.src = uploadedFileBased64
      setOriginalImage(uploadedFile)
      setCurrentImage(uploadedFileBased64 as string)
    }
  }

  const updateSettings = (updatedSettings: ResizerType) => {
    setSettings(updatedSettings)
    if (originalImage) resizeImage(originalImage, updatedSettings)
  }

  const renderSizeComparison = () => {
    if (!originalImage) return
    return <ImageStats originalSize={originalImage.size} updatedSize={updatedImageSize} quality={settings.quality} />
  }

  const getDimension = () => ({
    width: `${settings.width}px`,
    maxWidth: '80vw',
    maxHeight: 'calc(100vh - 300px)',
  })

  const onDownload = (filename: string) => {
    saveAs(updatedImage as string, `${filename}.${settings.fileType}`)
  }

  const renderImageComparison = () => {
    if (currentImage && updatedImage) {
      const styles = getDimension()
      return (
        <Drawer
          onImageUpload={fileChangedHandler}
          disabled={!currentImage}
          onDownload={onDownload}
          fileType={settings.fileType}
          sidebarComponent={
            <Settings
              originalImageDimension={originalImageDimension}
              originalImage={originalImage}
              updateSettings={updateSettings}
              settings={settings}
            />
          }
        >
          <div className="flex-vertical">
            {renderSizeComparison()}
            <div style={styles} className="container mx-auto p-3 md:p-8 overflow-y-auto dark:scrollbar">
              <ReactCompareImage aspectRatio="wider" leftImage={currentImage} rightImage={updatedImage} />
            </div>
            <Comparison originalImage={currentImage} updatedImage={updatedImage} />
          </div>
        </Drawer>
      )
    }
  }

  return (
    <div className="flex-vertical justify-center min-h-screen-main">
      {!currentImage && (
        <div className="w-full text-center">
          <h1 className="page-title">
            <span className="block xl:inline">Image Resizer</span>
          </h1>
          <FileInput handleFileUpload={handleFileUpload} />
        </div>
      )}
      {currentImage && isLoading ? <Loader /> : renderImageComparison()}
    </div>
  )
}

export default ImageResizer
