import ImageEditor from 'components/image-editor'
import { useDocumentTitle } from 'hooks/useDocumentTitle'

const ImageEditorPage = () => {
  useDocumentTitle('Imagator | Editor')
  return (
    <div className="bg-base-200">
      <ImageEditor />
    </div>
  )
}

export default ImageEditorPage
