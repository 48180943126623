import { InformationCircleIcon } from '@heroicons/react/outline'
import { ResizerType, ResizerTypeSettings } from 'components/image-resizer'

interface Props {
  updateSettings: (updatedSettings: ResizerType) => void
  settings: ResizerType
  originalImageDimension?: { [key: string]: number }
  originalImage?: File
}

const Settings = ({ settings, updateSettings, originalImageDimension, originalImage }: Props) => {
  const { width, height, fileType, quality, minWidth, minHeight } = settings
  const onUpdate = (key: ResizerTypeSettings, value: number | string) => {
    const copySettings = { ...settings }

    switch (key) {
      case 'fileType':
      case 'outputType': {
        copySettings[key] = value as string
        break
      }
      default: {
        copySettings[key] = value as number
      }
    }
    updateSettings(copySettings)
  }

  const renderImageInfo = () => {
    if (!originalImageDimension || !originalImage) return
    const { width, height } = originalImageDimension
    const { type, name } = originalImage
    return (
      <div className="form-control">
        <label className="input-group mb-1">
          <span className="w-24">Width</span>
          <input disabled type="number" value={width} className="input-box" />
        </label>
        <label className="input-group mb-1">
          <span className="w-24">Height</span>
          <input disabled type="number" value={height} className="input-box" />
        </label>
        <label className="input-group mb-1">
          <span className="w-24">Filename</span>
          <input disabled title={name} type="text" value={name} className="input-box" />
        </label>
        <label className="input-group mb-1">
          <span className="w-24">Filetype</span>
          <input disabled type="text" value={type} className="input-box" />
        </label>
      </div>
    )
  }

  const renderBasicOptions = () => {
    return (
      <div className="form-control">
        <label className="input-group mb-1">
          <span className="w-40">Max. Width</span>
          <input
            min={200}
            type="number"
            value={width}
            onChange={(e) => onUpdate('width', e.target.value)}
            className="input-box"
          />
        </label>
        <label className="input-group mb-1">
          <span className="w-40">Max. Height</span>
          <input
            min={200}
            type="number"
            value={height}
            onChange={(e) => onUpdate('height', e.target.value)}
            className="input-box"
          />
        </label>
        <label className="input-group mb-1">
          <span className="w-40">Quality</span>
          <input
            min={20}
            type="number"
            value={quality}
            onChange={(e) => onUpdate('quality', e.target.value)}
            className="input-box"
          />
        </label>
      </div>
    )
  }

  const renderAdvanceOptions = () => {
    return (
      <div className="form-control">
        <label className="input-group mb-1">
          <span className="w-40">Min. Width</span>
          <input
            min={200}
            type="number"
            value={minWidth}
            onChange={(e) => onUpdate('minWidth', e.target.value)}
            className="input-box"
          />
        </label>
        <label className="input-group mb-1">
          <span className="w-40">Min. Height</span>
          <input
            min={200}
            type="number"
            value={minHeight}
            onChange={(e) => onUpdate('minHeight', e.target.value)}
            className="input-box"
          />
        </label>
        <label className="input-group mb-1">
          <span className="w-40">Filetype</span>
          <select
            className="select select-bordered w-fit max-w-xs focus:outline-none"
            onChange={(e) => onUpdate('fileType', e.target.value)}
            value={fileType}
          >
            <option disabled>Select Filetype</option>
            <option value="png">PNG</option>
            <option value="jpeg">JPEG</option>
          </select>
        </label>
      </div>
    )
  }

  return (
    <div className="w-72 md:w-fit">
      <label htmlFor="my-drawer-4" className="btn btn-sm btn-square absolute left-2 top-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
        </svg>
      </label>
      <div className="mb-6 text-2xl font-medium text-center">Edit Settings</div>
      <div className="mb-6 text-xl font-medium">Image Info</div>
      <div>{renderImageInfo()}</div>
      <div className="flex mb-6 mt-6 text-xl font-medium">
        Basic Settings
        <div className="tooltip" data-tip="Preserved Ratio">
          <InformationCircleIcon className="h-4 w-4 ml-3" aria-hidden="true" />
        </div>
      </div>
      <div>{renderBasicOptions()}</div>
      <div className="mb-6 mt-6 text-xl font-medium">Advance Settings</div>
      <div>{renderAdvanceOptions()}</div>
    </div>
  )
}

export default Settings
