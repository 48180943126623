import Feature from 'components/feature'
import FeatureSection from 'components/feature-section'
import Hero from 'components/hero'
import { useDocumentTitle } from 'hooks/useDocumentTitle'
import BreakdownImageUrl from '../../assets/breakdown.png'
import CompressorImageURL from '../../assets/compressor.png'
import EditorImage2URL from '../../assets/editor-2.png'
import EditorImageURL from '../../assets/editor.png'
import ResizerImage2URL from '../../assets/resizer-2.png'
import ResizerImageURL from '../../assets/resizer.png'

const GetStarted = () => {
  useDocumentTitle('Imagator | Home')
  return (
    <div>
      <Hero />
      <FeatureSection />
      <section className="py-6 bg-base-200">
        <Feature
          reverse
          imgURL={CompressorImageURL}
          imgURL2={BreakdownImageUrl}
          title="Compressor"
          description="Compress a single image or multiples with a preview and a size improvement breakdown"
        />
        <Feature
          imgURL={EditorImageURL}
          imgURL2={EditorImage2URL}
          title="Editor"
          description="Crop, rotate and flip an image at a click of a button"
        />
        <Feature
          reverse
          isLast
          imgURL={ResizerImageURL}
          imgURL2={ResizerImage2URL}
          title="Resizer"
          description="Resize an image, adjust its dimensions and quality and convert it between JPEG and PNG"
        />
      </section>
    </div>
  )
}

export default GetStarted
