import FileInput from 'components/file-input'
import Loader from 'components/loader'
import { useState } from 'react'
import Compressor from './components/compressor'

const ImageCompressor = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [images, setImages] = useState<FileList>()

  const handleFilesUpload = async (uploadedFiles: FileList) => {
    if (uploadedFiles.length >= 1) {
      setIsLoading(true)
      setImages(uploadedFiles)
      setIsLoading(false)
    }
  }

  const renderImageCompressor = () => {
    if (images) {
      return <Compressor uploadedFiles={images} />
    }
  }

  return (
    <div className="flex-vertical justify-center min-h-screen-main">
      {!images?.length && (
        <div className="w-full text-center">
          <h1 className="page-title">
            <span className="block xl:inline">Image Compressor</span>
          </h1>
          <FileInput handleFilesUpload={handleFilesUpload} isMultiple />
        </div>
      )}
      {isLoading ? <Loader /> : renderImageCompressor()}
    </div>
  )
}

export default ImageCompressor
