import { useState } from 'react'
import FileErrorModal from '../file-error-modal'

interface Props {
  handleFileUpload?: (uploadedFile: File) => void
  handleFilesUpload?: (uploadedFiles: FileList) => void
  isMultiple?: boolean
}

const FileInput = ({ handleFileUpload, handleFilesUpload, isMultiple = false }: Props) => {
  const [hasError, setHasError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [showBorder, setShowBorder] = useState<boolean>(false)
  const isImageFile = (file: File) => ['image/png', 'image/jpeg'].includes(file.type)
  const onDragover = (event: React.DragEvent<HTMLDivElement>) => event.preventDefault() // needed to able to load content
  const onDragEnter = () => setShowBorder(true)
  const onDragLeave = () => setShowBorder(false)
  const onDrop = async (event: React.DragEvent) => {
    event.preventDefault() // prevent from opening in new window
    const { files } = event.dataTransfer
    if (files && files.length > 1 && !isMultiple) {
      setErrorMessage('Only one file can be uploaded at a time')
      setHasError(true)
    }
    if (files && files.length >= 1) {
      let isAllImageFiles = true
      for (let i = 0; i < files.length; i++) {
        if (!isImageFile(files[i])) {
          isAllImageFiles = false
          return
        }
      }

      if (isAllImageFiles) {
        await onUpload(files)
      } else {
        const errMsg = isMultiple
          ? 'Please select only image files to continue'
          : 'Please select an image file to continue'
        setErrorMessage(errMsg)
        setHasError(true)
      }
    }

    setShowBorder(false)
  }

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return
    await onUpload(event.target.files)
  }

  const onUpload = async (files: FileList) => {
    if (isMultiple && handleFilesUpload) {
      await handleFilesUpload(files)
    } else if (handleFileUpload) {
      await handleFileUpload(files[0])
    }
  }

  return (
    <div
      className={`flex justify-center items-center w-full min-h-screen-main-input ${
        showBorder && 'border-dashed border-2 border-primary opacity-80 bg-base-300 dark:bg-neutral'
      }`}
      onDragOver={onDragover}
      onDrop={onDrop}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
    >
      <span className="mr-2 text-base">Drop image{isMultiple && 's'} or </span>
      <input
        multiple={isMultiple}
        id="files"
        className="hidden"
        type="file"
        accept="image/png, image/jpeg"
        onChange={onChange}
      />
      <label
        className="block w-30 text-sm py-2 px-4
              rounded-full border-0
              hover:cursor-pointer
              btn-primary"
        htmlFor="files"
      >
        Choose File{isMultiple && 's'}
      </label>
      <FileErrorModal errorMessage={errorMessage} isOpen={hasError} onClose={setHasError} />
    </div>
  )
}

export default FileInput
