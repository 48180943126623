import { Progress } from './compressor'

interface Props {
  progress: Progress
}

const CompressionProgress = ({ progress }: Props) => {
  return <progress className="progress progress-accent w-56" value={progress.current} max={progress.total}></progress>
}

export default CompressionProgress
