import ImageCompressor from 'components/image-compressor'
import { useDocumentTitle } from 'hooks/useDocumentTitle'

const ImageCompressorPage = () => {
  useDocumentTitle('Imagator | Compressor')
  return (
    <div className="bg-base-200">
      <ImageCompressor />
    </div>
  )
}

export default ImageCompressorPage
