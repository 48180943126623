import { DownloadIcon } from '@heroicons/react/outline'
import { formatBytes, formatPercentage } from 'components/image-resizer/utils'
import { ProcessFiles } from './compressor'

interface Props {
  processedFiles: ProcessFiles
  quality: number
  onDownload: () => void
}

const CompressionStats = ({ processedFiles, quality, onDownload }: Props) => {
  let originalSize = 0
  let updatedSize = 0

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Object.entries(processedFiles).forEach(([_, value]) => {
    originalSize += value.originalSize
    updatedSize += value.updatedSize
  })

  const renderBreakdown = () => {
    return (
      <div className="overflow-x-auto">
        <table className="table w-full">
          <thead>
            <tr>
              <th>Filename</th>
              <th>Original Size</th>
              <th>Updated Size</th>
              <th>Size Improvement</th>
              <th>File Type</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(processedFiles).map(([key, value]) => {
              return (
                <tr key={key}>
                  <th>{key}</th>
                  <td>{formatBytes(value.originalSize)}</td>
                  <td>{formatBytes(value.updatedSize)}</td>
                  <td>{formatPercentage((1 - value.updatedSize / value.originalSize) * 100)}</td>
                  <td>{value.fileType}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div className="flex flex-col justify-center">
      <div className="page-title text-center mb-8">
        Results
        <div onClick={onDownload} className="tooltip" data-tip="Download Results">
          <DownloadIcon className="h-6 w-6 ml-3 hover:cursor-pointer" aria-hidden="true" />
        </div>
      </div>
      <div className="stats shadow stats-vertical md:stats-horizontal mb-6 md:mx-8">
        <div className="stat place-items-center">
          <div className="stat-title">Original Size</div>
          <div className="stat-value">{formatBytes(originalSize)}</div>
        </div>
        <div className="stat place-items-center">
          <div className="stat-title">Updated Size</div>
          <div className="stat-value">{formatBytes(updatedSize)}</div>
        </div>
        <div className="stat place-items-center">
          <div className="stat-title">Image Quality</div>
          <div className="stat-value">{`${quality}%`}</div>
        </div>
        <div className="stat place-items-center">
          <div className="stat-title">Size Improvement</div>
          <div className="stat-value text-secondary">↘︎{formatPercentage((1 - updatedSize / originalSize) * 100)}</div>
        </div>
      </div>
      <div>{renderBreakdown()}</div>
    </div>
  )
}

export default CompressionStats
