import { formatBytes, formatPercentage } from '../utils'

interface Props {
  originalSize: number
  updatedSize: number
  quality: number
}

const ImageStats = ({ originalSize, updatedSize, quality }: Props) => {
  return (
    <div className="stats shadow stats-vertical md:stats-horizontal mb-6 md:mx-8">
      <div className="stat place-items-center">
        <div className="stat-title">Original Size</div>
        <div className="stat-value">{formatBytes(originalSize)}</div>
      </div>
      <div className="stat place-items-center">
        <div className="stat-title">Updated Size</div>
        <div className="stat-value">{formatBytes(updatedSize)}</div>
      </div>
      <div className="stat place-items-center">
        <div className="stat-title">Image Quality</div>
        <div className="stat-value">{`${quality}%`}</div>
      </div>
      <div className="stat place-items-center">
        <div className="stat-title">Size Improvement</div>
        <div className="stat-value text-secondary">↘︎{formatPercentage((1 - updatedSize / originalSize) * 100)}</div>
      </div>
    </div>
  )
}

export default ImageStats
