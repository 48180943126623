import { ArrowCircleDownIcon, EyeOffIcon } from '@heroicons/react/outline'
import Wave from './components/wave'

const Hero = () => {
  const scrollToFeatureSection = () => {
    document.getElementById('feature-section')?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
  }

  return (
    <div className="min-h-screen flex-vertical justify-center">
      <div className="max-w-md flex-vertical flex-1 justify-center">
        <h1 className="flex items-center text-5xl font-bold tracking-wider">
          Hello there
          <span className="ml-3 animate-wave text-5xl">👋</span>
        </h1>
        <div className="flex-vertical gap-1 py-6 tracking-wider">
          <p>Compress, convert, edit and resize your images</p>
          <p>No uploading. Files never leave your machine.</p>
          <p className="flex justify-center">
            Completely free and private <EyeOffIcon className="h-6 w-6 ml-1" />
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <a className="btn btn-primary" href="/compressor">
            Get Started
          </a>
          <div>
            <a
              href="https://www.producthunt.com/posts/imagator?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-imagator"
              target="_blank"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=351232&theme=dark"
                alt="Imagator - Privately&#0032;compress&#0044;&#0032;modify&#0032;and&#0032;resize&#0032;unlimited&#0032;images | Product Hunt"
                className="w-[250px] h-[54px]"
                width="250"
                height="54"
              />
            </a>
          </div>
        </div>
      </div>
      <div onClick={scrollToFeatureSection} className="flex grow-0 mb-20 justify-center cursor-pointer">
        <ArrowCircleDownIcon className="h-12 w-12 animate-bounce" aria-hidden="true" />
      </div>
      <Wave />
    </div>
  )
}

export default Hero
