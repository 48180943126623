import { useEventListener } from 'hooks/useEventListener'
import { useRef, useState } from 'react'

interface Props {
  disabled?: boolean
  fileType: string
  onDownload: (fileName: string) => void
  children?: React.ReactNode
}

const Modal = ({ children, disabled, fileType, onDownload }: Props) => {
  const [filename, setFilename] = useState<string>('')

  const inputRef = useEventListener('keydown', (event) => {
    if (event.code === 'Enter') {
      event.preventDefault()
      handleDownload()
    }
  })
  const modalInputRef = useRef<HTMLInputElement>(null)

  const onFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const closeModal = () => {
    if (modalInputRef.current) {
      modalInputRef.current.click()
    }
  }

  const handleDownload = () => {
    if (filename) {
      onDownload(filename)
      closeModal()
    }
  }

  return (
    <div>
      {!children && (
        <label htmlFor="download-modal" className={`modal-button btn btn-primary ml-3 ${disabled && 'btn-disabled'}`}>
          Download
        </label>
      )}
      {children}
      <input ref={modalInputRef} type="checkbox" id="download-modal" className="modal-toggle" onClick={onFocus} />
      <div className="modal">
        <div className="modal-box relative">
          <label htmlFor="download-modal" className="btn btn-sm btn-circle absolute right-2 top-2">
            ✕
          </label>
          <div className="flex flex-col justify-center mx-auto pt-10">
            <label className="input-group mb-1">
              <span className="w-24">Filename</span>
              <input
                ref={inputRef}
                type="text"
                value={filename}
                onChange={(e) => setFilename(e.target.value)}
                className="input input-bordered focus:outline-none appearance-none w-full"
              />
              <span>.{fileType.toLowerCase()}</span>
            </label>
            <div className="flex justify-center mt-6">
              <div
                onClick={handleDownload}
                className={`drawer-button btn btn-primary mr-3 ${!filename && 'btn-disabled'}`}
              >
                Download
              </div>
              <label htmlFor="download-modal" className="drawer-button btn btn-ghost">
                Cancel
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
