interface Props {
  errorMessage: string
  isOpen: boolean
  onClose: (isClose: boolean) => void
}

const FileErrorModal = ({ isOpen, onClose, errorMessage }: Props) => {
  const handleClose = () => {
    onClose(!isOpen)
  }
  return (
    <div>
      <div className={`modal ${isOpen && 'modal-open'}`} onClick={handleClose}>
        <div className="modal-box relative">
          <label onClick={handleClose} className="btn btn-sm btn-circle absolute right-2 top-2">
            ✕
          </label>
          <h3 className="text-lg font-bold">Error</h3>
          <p className="py-4">{errorMessage}</p>
        </div>
      </div>
    </div>
  )
}

export default FileErrorModal
