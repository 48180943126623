const Wave = () => (
  <div className="min-w-full absolute bottom-0 -z-10">
    <svg viewBox="0 0 900 600" xmlns="http://www.w3.org/2000/svg" version="1.1">
      <path
        d="M0 475L21.5 467C43 459 86 443 128.8 440.2C171.7 437.3 214.3 447.7 257.2 445.3C300 443 343 428 385.8 418.2C428.7 408.3 471.3 403.7 514.2 413.2C557 422.7 600 446.3 642.8 457.8C685.7 469.3 728.3 468.7 771.2 462.2C814 455.7 857 443.3 878.5 437.2L900 431L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z"
        className="fill-primary"
      ></path>
      <path
        d="M0 443L21.5 452.2C43 461.3 86 479.7 128.8 483.8C171.7 488 214.3 478 257.2 470C300 462 343 456 385.8 457.7C428.7 459.3 471.3 468.7 514.2 470C557 471.3 600 464.7 642.8 461.8C685.7 459 728.3 460 771.2 463.7C814 467.3 857 473.7 878.5 476.8L900 480L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z"
        className="fill-primary-focus"
      ></path>
      <path
        d="M0 496L21.5 499C43 502 86 508 128.8 511C171.7 514 214.3 514 257.2 512.5C300 511 343 508 385.8 510.2C428.7 512.3 471.3 519.7 514.2 516.7C557 513.7 600 500.3 642.8 493.3C685.7 486.3 728.3 485.7 771.2 484C814 482.3 857 479.7 878.5 478.3L900 477L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z"
        className="fill-primary-focus"
      ></path>
      <path
        d="M0 547L21.5 542.8C43 538.7 86 530.3 128.8 526.7C171.7 523 214.3 524 257.2 523.8C300 523.7 343 522.3 385.8 519.3C428.7 516.3 471.3 511.7 514.2 513.3C557 515 600 523 642.8 529.2C685.7 535.3 728.3 539.7 771.2 542.2C814 544.7 857 545.3 878.5 545.7L900 546L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z"
        className="fill-primary"
      ></path>
      <path
        d="M0 555L21.5 553.7C43 552.3 86 549.7 128.8 552.5C171.7 555.3 214.3 563.7 257.2 568.2C300 572.7 343 573.3 385.8 568.8C428.7 564.3 471.3 554.7 514.2 554C557 553.3 600 561.7 642.8 562.8C685.7 564 728.3 558 771.2 553.2C814 548.3 857 544.7 878.5 542.8L900 541L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z"
        className="fill-primary"
      ></path>
    </svg>
  </div>
)

export default Wave
