interface Props {
  imgURL: string
  imgURL2?: string
  title: string
  description: string
  reverse?: boolean
  isLast?: boolean
}

const Feature = ({ imgURL, imgURL2, title, description, reverse, isLast }: Props) => {
  return (
    <div className={`flex justify-center min-w-5xl ${!isLast && 'mb-3'}`}>
      <div
        className={`hero-content flex-col md:min-w-screen-2xl bg-base-300 rounded-lg ${
          reverse ? 'md:flex-row-reverse' : 'md:flex-row'
        }  justify-between md:p-16 `}
      >
        <div className="flex flex-col gap-2 md:flex-row">
          <img
            src={imgURL}
            className={`max-w-sm rounded-lg shadow-2xl transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300  ${
              reverse ? 'md:ml-6' : 'md:mr-6'
            }`}
          />
          {imgURL2 && (
            <img
              src={imgURL2}
              className={`max-w-sm rounded-lg shadow-2xl transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 ${
                reverse ? 'md:ml-6' : 'md:mr-6'
              }`}
            />
          )}
        </div>
        <div className="max-w-xs text-center md:text-inherit">
          <h1 className={`text-5xl font-bold ${reverse ? 'md:text-left' : 'md:text-right'}`}>{title}</h1>
          <p className={`py-6 ${reverse ? 'md:text-left' : 'md:text-right'}`}>{description}</p>
        </div>
      </div>
    </div>
  )
}

export default Feature
