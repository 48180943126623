export const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

export const getFileType = (type: string) => {
  if (type.includes('jpeg')) return 'jpeg'
  if (type.includes('png')) return 'png'

  return ''
}
