import ImageResizer from 'components/image-resizer'
import { useDocumentTitle } from 'hooks/useDocumentTitle'

const ImageResizerPage = () => {
  useDocumentTitle('Imagator | Resizer')
  return (
    <div className="bg-base-200">
      <ImageResizer />
    </div>
  )
}

export default ImageResizerPage
