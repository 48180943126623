import Modal from 'components/download-modal'
import { ResizerType } from 'components/image-resizer'
import Loader from 'components/loader'
import saveAs from 'file-saver'
import JSZip from 'jszip'
import ReactCompareImage from 'libraries/react-compare-image'
import { useEffect, useState } from 'react'
import Resizer from 'react-image-file-resizer'
import { getFileType, toBase64 } from 'utils'
import CompressionProgress from './compression-progress'
import CompressionStats from './compression-stats'

// resizer settings
const OUTPUT_TYPE = 'file'
const ROTATION = 0

export interface ProcessFiles {
  [filename: string]: {
    originalSize: number
    updatedSize: number
    originalImageURL: string
    updatedImageURL: string
    fileType: string
  }
}

export interface Progress {
  current: number
  total: number
}

interface Props {
  uploadedFiles: FileList
}

const Compressor = ({ uploadedFiles }: Props) => {
  const [previewImageOriginal, setPreviewImageOriginal] = useState<string>('')
  const [previewImageUpdated, setPreviewImageUpdated] = useState<string>('')
  const [previewFilename, setPreviewFilename] = useState<string>('')
  const [previewImageSettings, setPreviewImageSettings] =
    useState<Pick<ResizerType, 'width' | 'height' | 'fileType' | 'quality'>>()
  const [imageQuality, setImageQuality] = useState<number>(70)
  const [fileType, setFileType] = useState<string>('zip')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [progress, setProgress] = useState<Progress>({ current: 0, total: 0 })
  const [processedFiles, setProcessedFiles] = useState<ProcessFiles>()
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [isProcessed, setIsProcessed] = useState<boolean>(false)
  const [outputFilename, setOutputFilename] = useState<string>('')

  useEffect(() => {
    setProgress((previousState) => ({ ...previousState, ...{ total: uploadedFiles.length, current: 0 } }))
    if (uploadedFiles.length === 1) {
      // support single file download
      const fileType = getFileType(uploadedFiles[0].type)
      setFileType(fileType)
    }
    setProcessedFiles(undefined)
    setupPreview(uploadedFiles[0])
  }, [uploadedFiles])

  const setupPreview = async (originalImage: File) => {
    setIsLoading(true)
    const originalImageBased64 = await toBase64(originalImage)
    const fileType = getFileType(originalImage.type)
    const img: HTMLImageElement = document.createElement('img')
    img.onload = function () {
      setPreviewImageOriginal(originalImageBased64)
      setPreviewFilename(originalImage.name)
      const imageSettings = { width: img.width, height: img.height, quality: imageQuality, fileType }
      setPreviewImageSettings(imageSettings)
      resizeImage(originalImage, imageSettings)
    }
    img.src = originalImageBased64
  }

  const imageCallback = async (updatedImage: File) => {
    const updatedImageBased64 = await toBase64(updatedImage)
    setPreviewImageUpdated(updatedImageBased64)
    setIsLoading(false)
  }

  const resizeImage = (
    imageFile: File,
    imageSettings: Pick<ResizerType, 'width' | 'height' | 'fileType' | 'quality'>,
    callback: (updatedImage: File) => void = imageCallback
  ) => {
    const { width, height, fileType, quality } = imageSettings
    try {
      Resizer.imageFileResizer(
        imageFile,
        width,
        height,
        fileType,
        quality,
        ROTATION,
        (updatedImage) => callback(updatedImage as File),
        OUTPUT_TYPE
      )
    } catch (err) {
      console.log(err)
    }
  }

  const processFiles = async () => {
    for (let i = 0; i < uploadedFiles.length; i++) {
      setProgress((previousState) => ({ ...previousState, ...{ current: previousState.current + 1 } }))

      const file = uploadedFiles[i]
      const originalImageURL = await toBase64(file)
      const imageFileType = getFileType(file.type)

      const fileCallback = async (updatedImage: File) => {
        const filename = file.name
        const originalSize = file.size
        const updatedImageURL = await toBase64(updatedImage)
        const updatedSize = updatedImage.size
        setProcessedFiles((previousState) => ({
          ...previousState,
          ...{
            [filename]: { originalImageURL, originalSize, fileType: imageFileType, updatedImageURL, updatedSize },
          },
        }))
      }
      const img: HTMLImageElement = document.createElement('img')
      img.onload = function () {
        const imageSettings = { width: img.width, height: img.height, quality: imageQuality, fileType: imageFileType }
        resizeImage(file, imageSettings, fileCallback)
      }
      img.src = originalImageURL
    }
  }

  const onCompression = async (filename: string) => {
    setIsProcessing(true)
    await processFiles()
    setOutputFilename(filename)
    setIsProcessing(false)
    setIsProcessed(true)
  }

  const onDownload = async () => {
    if (!processedFiles) return
    const zip = new JSZip()
    Object.entries(processedFiles).forEach(([key, value]) => {
      zip.file(key, value.updatedImageURL)
    })
    zip.generateAsync({ type: 'blob' }).then(function (blob) {
      saveAs(blob, `${outputFilename}.${fileType}`)
    })
  }

  const getDimension = () => ({
    width: `${previewImageSettings?.width}px`,
    maxWidth: '80vw',
    maxHeight: 'calc(100vh - 300px)',
  })

  const onQualityUpdate = (updatedQuality: number) => {
    setImageQuality(updatedQuality)
    if (previewImageSettings) {
      resizeImage(uploadedFiles[0], { ...previewImageSettings, ...{ quality: updatedQuality } }, imageCallback)
    }
  }

  const renderOptions = () => (
    <div className="flex flex-col flex-grow-0 md:flex-row bg-opacity-70 bg-neutral rounded-md px-1 pt-1">
      <div className="form-control flex flex-row gap-3">
        <label className="input-group mb-1">
          <span className="w-28">Total Files</span>
          <input disabled type="number" value={uploadedFiles.length} className="input-box w-14" />
        </label>
        <label className="input-group mb-1">
          <span className="w-36">Preview File</span>
          <input disabled type="text" value={previewFilename} className="input-box w-40" />
        </label>
        <label className="input-group mb-1">
          <span className="w-24">Quality</span>
          <input
            min={20}
            type="number"
            value={imageQuality}
            onChange={(e) => onQualityUpdate(e.target.value as unknown as number)}
            className="input input-bordered focus:outline-none w-14"
          />
          <span>%</span>
        </label>
      </div>
      <div className="flex justify-center ml-3">
        <Modal onDownload={onCompression} fileType={fileType}>
          <label htmlFor="download-modal" className={`modal-button btn btn-primary ml-3`}>
            Compress
          </label>
        </Modal>
      </div>
    </div>
  )
  const renderPreview = () => (
    <div
      style={getDimension()}
      className="container flex-vertical flex-grow-1 mx-auto p-3 md:p-8 overflow-y-auto dark:scrollbar"
    >
      <div className="text-center text-xl">Preview</div>
      <div className="flex justify-between w-full">
        <div className="pb-3">Before</div>
        <div className="pb-3">After</div>
      </div>
      {<ReactCompareImage aspectRatio="wider" leftImage={previewImageOriginal} rightImage={previewImageUpdated} />}
    </div>
  )
  const renderStats = () =>
    processedFiles && (
      <CompressionStats quality={imageQuality} processedFiles={processedFiles} onDownload={onDownload} />
    )
  const renderProgress = () => <CompressionProgress progress={progress} />
  const renderContent = () => {
    return (
      <div className="flex-vertical">
        {!isProcessing && !isProcessed && renderOptions()}
        {!isProcessing && !isProcessed && renderPreview()}
        {isProcessing && renderProgress()}
        {isProcessed && renderStats()}
      </div>
    )
  }

  return <div className="flex-vertical min-h-screen-main-input mt-10">{isLoading ? <Loader /> : renderContent()}</div>
}

export default Compressor
