import { HeartIcon } from '@heroicons/react/outline'
import NightlyOwlImageURL from 'assets/nightlyowl64.png'
import ThemeToggle from 'components/theme-toggle'
import useRedirect from 'hooks/useRedirect'
import BuyMeACoffee from './components/buymeacoffee'

const NAVIGATION = [
  {
    name: 'Compressor',
    link: '/compressor',
  },
  {
    name: 'Editor',
    link: '/editor',
  },
  {
    name: 'Resizer',
    link: '/resizer',
  },
]

interface Props {
  children?: React.ReactNode
}

const Navbar = ({ children }: Props) => {
  const { history } = useRedirect()
  const currentNavSelection = history?.location?.pathname?.split('/')[1]

  return (
    <div className="flex flex-col min-h-screen">
      <div className="navbar bg-base-100">
        <div className="navbar-start md:hidden">
          <div className="dropdown ">
            <label tabIndex={0} className="btn btn-ghost btn-circle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" />
              </svg>
            </label>
            <ul tabIndex={0} className="menu dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
              {NAVIGATION.map(({ name, link }) => (
                <li key={name} className="hover-bordered">
                  <a
                    className={`${currentNavSelection && link.includes(currentNavSelection) && 'btn-active'}`}
                    href={link}
                  >
                    {name}
                  </a>
                </li>
              ))}
              <div className="mt-5">
                <ThemeToggle />
              </div>
            </ul>
          </div>
        </div>
        <div className="navbar-center md:navbar-start">
          <a className="btn btn-ghost normal-case text-2xl" href="/">
            <img src={NightlyOwlImageURL} alt="Nightly Owl" className="w-6 h-6 mr-1 hover:animate-spin" />
            Imagator
          </a>
        </div>
        <div className="navbar-end">
          <div className="hidden md:flex items-center">
            <div className="mr-3 lg:mr-8">
              {NAVIGATION.map(({ name, link }) => (
                <a
                  href={link}
                  key={name}
                  className={`${
                    currentNavSelection && link.includes(currentNavSelection) && 'btn-active'
                  } btn btn-ghost normal-case text-base lg:text-lg`}
                >
                  {name}
                </a>
              ))}
            </div>
            <ThemeToggle />
          </div>
        </div>
      </div>
      <main className="flex-grow">{children}</main>
      <footer className="flex justify-center px-6 pt-3 flex-col-reverse items-center md:flex-row lg:text-left bg-base-300">
        <div className="flex items-center justify-center text-center p-4 flex-grow md:ml-[280px]">
          <span>Made with</span>
          <HeartIcon
            className="h-6 w-6 ml-3 mr-3 dark:text-pink-500 fill-current text-pink-300 animate-pulse"
            aria-hidden="true"
          />
          <span>{`Imagator @ ${new Date().getFullYear()}`}</span>
        </div>
        <div className="flex-grow-0">
          <BuyMeACoffee />
        </div>
      </footer>
    </div>
  )
}

export default Navbar
