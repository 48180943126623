const useDarkMode = () => {
  const loadDarkMode = () => {
    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    const isDarkMode = localStorage.theme === 'dark'
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }

  const updateDarkMode = (enabledDarkMode: boolean) => {
    if (enabledDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }

  return { loadDarkMode, updateDarkMode }
}

export default useDarkMode
