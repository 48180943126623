import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import saveAs from 'file-saver'
import { useState } from 'react'
import { getFileType } from 'utils'
import Toolbar from './toolbar'

interface Props {
  imageType: string
  imgUrl: string
  onReset: (currentImage: string) => void
}

const Editor = ({ imgUrl, imageType, onReset }: Props) => {
  const [cropper, setCropper] = useState<Cropper>()
  const [isCropping, setIsCropping] = useState<boolean>(false)
  const [isCropped, setIsCropped] = useState<boolean>(false)
  const [imageUrl, setImageUrl] = useState<string>(imgUrl)
  const [previousUrl] = useState<string>(imgUrl)

  const generateCropper = (img: HTMLImageElement) => {
    const imgCopper = new Cropper(img, {
      autoCrop: false,
      dragMode: 'move',
      background: false,
      crop: ({ detail }) => {
        if (detail.width > 0 && detail.height > 0 && !isCropping) {
          setIsCropping(true)
        }
      },
    })
    setCropper(imgCopper)
  }

  const start = (event: React.SyntheticEvent) => {
    generateCropper(event.target as HTMLImageElement)
  }

  const stop = () => {
    if (cropper) {
      cropper.destroy()
    }
  }

  const crop = () => {
    if (isCropping && cropper) {
      setIsCropped(true)
      setIsCropping(false)
      const croppedImageURL = cropper
        .getCroppedCanvas(
          imageType === 'image/png'
            ? {}
            : {
                fillColor: '#fff',
              }
        )
        .toDataURL(imageType)

      setImageUrl(croppedImageURL)
      stop()
    }
  }

  const clear = () => {
    if (isCropping && cropper) {
      cropper.clear()
      setIsCropping(false)
    }
  }

  const restore = () => {
    if (isCropped) {
      setImageUrl(previousUrl)
      setIsCropped(false)
      stop()
    }
  }

  const onDoubleClick = (event: React.BaseSyntheticEvent) => {
    if (event.target.className.indexOf('cropper-face') >= 0) {
      event.preventDefault()
      event.stopPropagation()
      crop()
    }
  }

  const fileType = getFileType(imageType)
  const onDownload = (filename: string) => {
    saveAs(imageUrl as string, `${filename}.${fileType}`)
  }

  const reset = () => {
    stop()
    setIsCropped(false)
    setIsCropping(false)
    onReset('')
  }

  const onAction = (action: string) => {
    if (!cropper) return
    switch (action) {
      case 'move':
      case 'crop':
        cropper.setDragMode(action)
        break
      case 'zoom-in':
        cropper.zoom(0.1)
        break
      case 'zoom-out':
        cropper.zoom(-0.1)
        break
      case 'rotate-left':
        cropper.rotate(-90)
        break
      case 'rotate-right':
        cropper.rotate(90)
        break
      case 'flip-horizontal':
        cropper.scaleX(-cropper.getData().scaleX || -1)
        break
      case 'flip-vertical':
        cropper.scaleY(-cropper.getData().scaleY || -1)
        break
      case 'reset':
        reset()
        break
      case 'restore':
        restore()
        break
      case 'clear':
        clear()
        break
      case 'crop-image':
        crop()
        break
      default:
    }
  }

  return (
    <div className="min-h-editor-main-sm md:min-h-editor-main w-full">
      <div onDoubleClick={onDoubleClick} className="min-h-editor-main-sm md:min-h-editor-main w-full">
        <img onLoad={start} onLoadStart={start} src={imageUrl} className="hidden" alt="editing image" />
      </div>
      <Toolbar fileType={fileType} onDownload={onDownload} onAction={onAction} />
    </div>
  )
}

export default Editor
