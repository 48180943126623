import { useEffect, useState } from 'react'

interface Props {
  originalImage: string
  updatedImage: string
}

interface Dimension {
  width: number
  height: number
}

const Comparison = ({ originalImage, updatedImage }: Props) => {
  const [originalImageDimension, setOriginalImageDimension] = useState<Dimension>()
  const [updatedImageDimension, setUpdatedImageDimension] = useState<Dimension>()

  useEffect(() => {
    const loadImage = (setImageDimensions: (dims: Dimension) => void, imageUrl: string) => {
      const img = new Image()
      img.src = imageUrl

      img.onload = () => {
        setImageDimensions({
          height: img.height,
          width: img.width,
        })
      }
      img.onerror = (err) => {
        console.log('img error')
        console.error(err)
      }
    }
    loadImage(setOriginalImageDimension, originalImage)
    loadImage(setUpdatedImageDimension, updatedImage)
  }, [originalImage, updatedImage])

  return (
    <div className="container flex-vertical md:flex-row px-3 mt-6 md:pb-3 gap-1">
      <div className="container flex-vertical gap-3">
        <div className="flex-vertical gap-2 md:gap-0">
          <div className="opacity-90 text-lg">Original Image</div>
          <div className="flex-vertical md:flex-row md:gap-3">
            <span className="opacity-70">{`Width: ${originalImageDimension?.width}px`}</span>
            <span className="opacity-70">{`Height: ${originalImageDimension?.height}px`}</span>
          </div>
        </div>
        <img src={originalImage} alt="original image" />
      </div>
      <div className="container flex-vertical gap-3 mt-6 md:mt-0">
        <div className="flex-vertical gap-2 md:gap-0">
          <div className="opacity-90 text-lg">Updated Image</div>
          <div className="flex-vertical md:flex-row md:gap-3">
            <span className="opacity-70">{`Width: ${updatedImageDimension?.width}px`}</span>
            <span className="opacity-70">{`Height: ${updatedImageDimension?.height}px`}</span>
          </div>
        </div>
        <img src={updatedImage} alt="updated image" />
      </div>
    </div>
  )
}

export default Comparison
