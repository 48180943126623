import GetStarted from 'pages/get-started'
import ImageEditorPage from 'pages/image-editor'
import ImageResizerPage from 'pages/image-resizer'
import ImagesCompressor from 'pages/images-compressor'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Navbar from '../components/navbar'

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Navbar>
          <Route exact path="/" component={GetStarted} />
          <Route exact path="/resizer" component={ImageResizerPage} />
          <Route exact path="/editor" component={ImageEditorPage} />
          <Route exact path="/compressor" component={ImagesCompressor} />
        </Navbar>
      </Switch>
    </BrowserRouter>
  )
}

export default Router
